import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

let mm = gsap.matchMedia();


// Testimonial Swiper
const testimonialSwiper = new Swiper('.testimonial-swiper', {
    
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    
});



// scrollback functionality

function debounce(func, timeout = 10){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

const header = document.getElementById("header")

var current = window.scrollY
var paddedUp = 0
var paddedDown = 0


function saveInput(){
  
    if(window.scrollY > 50) {
        if(window.scrollY < current){
          if(window.scrollY < paddedUp) {
            header.classList.remove("scroll-down")
            header.classList.add("scroll-up")
          }
          paddedDown = window.scrollY + 50
          
        } else {
          if(window.scrollY > paddedDown) {
            header.classList.remove("scroll-up")
            header.classList.add("scroll-down")

            paddedUp = window.scrollY - 50
          }
         
        }
        current = window.scrollY
    } else {
      header.classList.remove("scroll-down")
      header.classList.add("scroll-up")
    }
 
}

const logoScroll = debounce(() => saveInput());
window.addEventListener("scroll", logoScroll);


// mobile nav functionality
const mobileNav = document.getElementById("mobile-nav-menu")
const navBtn = document.getElementById("mobile-nav-btn")
const navEx = document.getElementById("nav-menu-exit-btn")

if(navEx){
  navEx.addEventListener("click", function() {
      if(mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.remove("mobile-nav-active")
        
      }   
  })
}
if(navBtn){
navBtn.addEventListener("click", function() {
    if(!mobileNav.classList.contains("mobile-nav-active")) {
      mobileNav.classList.add("mobile-nav-active")
      
    }
})
}

// btn animation
const btns = document.querySelectorAll(".btn")
for (let i = 0; i < btns.length; i++) {
    const el = btns[i];
    if(el.classList.contains("border-blue")){
      if(location.pathname === "/about"){
        el.innerHTML = el.innerHTML + `<div id="btn-bar-${i}" class="transition-all absolute bg-blue" style="width: 100%; height:2px"></div>`
      }else{
        el.innerHTML = el.innerHTML + `<div id="btn-bar-${i}" class="transition-all absolute bg-blue" style="width: 0%; height:2px"></div>`
      }
      
    }else{
      if(el.classList.contains("btn-underline")){
        el.innerHTML = el.innerHTML + `<div id="btn-bar-${i}" class="transition-all absolute bg-white" style="width: 100%; height:2px"></div>`
      }else {
        el.innerHTML = el.innerHTML + `<div id="btn-bar-${i}" class="transition-all absolute bg-white" style="width: 0%; height:2px"></div>`
      }
      
    }
    
}

for (let i = 0; i < btns.length; i++) {
    const el = btns[i];
    const btnBar = document.getElementById(`btn-bar-${i}`)
    el.addEventListener("mouseover", () => {
        btnBar.style.width = "100%"
    })
    el.addEventListener("mouseout", () => {
        btnBar.style.width = "0%"
    })
}


// Check for user
var cookie = ".ASPXAUTH"
const logoutBtn = document.querySelector(".logout-btn")
const loginBtn = document.querySelector(".login-btn")

let x = document.cookie;
let cookiesObject = Object.fromEntries(x.split('; ').map(c => c.split('=')))

function eraseCookie(name) {   
  document.cookie = name+'=; Max-Age=-99999999;';  
}

if(cookiesObject.hasOwnProperty(cookie)){
  logoutBtn.classList.remove("hidden")
  loginBtn.innerHTML = "View Account"
} else {
  logoutBtn.classList.add("hidden")
  loginBtn.innerHTML = "Login"
}

logoutBtn.addEventListener("click", () => {
  eraseCookie(cookie)
  location.reload()
})


// nav functionality
const navParents = document.querySelectorAll(".nav-parent")
const navChildren = document.querySelectorAll(".nav-children")

for (let i = 0; i < navParents.length; i++) {
    const el = navParents[i];
    
    el.addEventListener("mouseover", () => {
        navChildren[i].classList.add("nav-child-active")
    })
    el.addEventListener("mouseout", () => {
        navChildren[i].classList.remove("nav-child-active")
    })
}

// Audio Button
const audio = document.getElementById("homepage-audio")
const audioBtn = document.getElementById("audio-toggle")
const audioToggleImage = document.getElementById("audio-toggle-img")
const audioToggleImageHover = document.getElementById("audio-toggle-img-hover")
let audioState = false

if(audioBtn && audio){
console.log(window.location.href)
  audioBtn.addEventListener("click", () => {
    if(audioState){
        audio.pause();
        audioToggleImage.src = window.location.href + "volumes/static/sound.svg"
        audioToggleImageHover.src = window.location.href + "volumes/static/sound-hover.svg"
        audioState = false
    } else {
        audio.play();
        audioToggleImage.src = window.location.href + "volumes/static/sound-stop.svg"
        audioToggleImageHover.src = window.location.href + "volumes/static/sound-stop-hover.svg"
        audioState = true
    }
  })

  audioBtn.addEventListener("mouseover", () => {
    gsap.to(audioToggleImageHover, {alpha: 1, ease: "expo"})
  })
  audioBtn.addEventListener("mouseout", () => {
      gsap.to(audioToggleImageHover, {alpha: 0, ease: "expo"})
  })
}
